import { useCallback, useEffect, useMemo, useState } from 'react';
import Button, { ButtonType } from '../../components/Button';
import Collapsible from '../../components/Collapsible';
import MainLayout from '../../layouts/main-layout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { driverForm, getAdminFormErrors, initialForm, initialFormErrors } from '../../forms/brokerIssueCoverNote.form';
import { handleApiResponseForm, validateForm } from '../../utils/formHelper';
import { postData } from '../../utils/fetchApi';
import { useAuth } from '../../contexts/auth';
import PolicyDetails from '../../components/CoverNote/PolicyDetails';
import PolicyHolderDetails from '../../components/CoverNote/PolicyHolderDetails';
import DriverDetails from '../../components/CoverNote/DriverDetails';
import VehicleDetails from '../../components/CoverNote/VehicleDetails';
import Cover from '../../components/CoverNote/Cover';
import PolicyUse from '../../components/CoverNote/PolicyUse';
import SpecialConditions from '../../components/CoverNote/SpecialConditions';
import BrokerDetails from '../../components/CoverNote/BrokerDetails';
import useGetBroker from '../../hooks/useGetBroker';

const IssueCoverNote: React.FC = () => {
	let navigate = useNavigate();
	const [formData, setFormData] = useState(initialForm);
	const { user, silentRenew } = useAuth();
	const { state, pathname } = useLocation();
	const [numberOfDaysToExpire, setNumberOfDaysToExpire] = useState('');
	const [formErrors, setFormErrors] = useState(initialFormErrors);
	const [hasError, setHasError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { broker } = useGetBroker(user?.userId as string, true);

	useEffect(() => {
		if (broker && user?.isAdmin && !state) {
			setFormData({
				...formData,
				agencyName: broker.brokerName,
				agencyNumber: broker.agencyNumber,
				agencyTelephoneNumber: broker.workTelephoneNumber,
				agencyAddressLine1: broker.companyAddressLine1,
				agencyAddressLine2: broker.companyAddressLine2,
				agencyPostCode: broker.postCode,
			});
			setFormErrors(getAdminFormErrors(formErrors));
		} else if (state) {
			const currentInfo = state?.isExtension ? state.coverNote : state;
			const splittedAddress = String(currentInfo?.agencyAddress).split(',');

			setFormData({
				...currentInfo,
				agencyAddressLine1: splittedAddress[0],
				agencyAddressLine2: splittedAddress.length > 1 ? splittedAddress.slice(1).join(',').trim() : null,
				agencyTelephoneNumber: currentInfo.agencyTelephoneNumber,
			});
		}
	}, [broker, state]);

	useEffect(() => {
		if (state && Object.keys(state).length > 0) setFormData(state?.isExtension ? state.coverNote : state);
	}, [state]);

	useEffect(() => {
		if (!state) setFormData({ ...initialForm, additionalDrivers: [{ ...driverForm }] });
	}, [state]);

	const [collapsedItems, setCollapsedItems] = useState({
		brokerDetails: !user?.isAdmin,
		policyDetails: !!user?.isAdmin,
		policyHolderDetails: true,
		driverDetails: true,
		vehicleDetails: true,
		policyUse: true,
		cover: true,
		specialConditions: true,
	});

	const isCollapsed = useMemo(() => {
		let property: keyof typeof collapsedItems;

		for (property in collapsedItems) {
			if (collapsedItems[property] === false) continue;

			return true;
		}

		return false;
	}, [collapsedItems]);

	const initCollapsedItems = (inititalValue: boolean) => ({
		policyDetails: inititalValue,
		policyHolderDetails: inititalValue,
		driverDetails: inititalValue,
		vehicleDetails: inititalValue,
		policyUse: inititalValue,
		cover: inititalValue,
		specialConditions: inititalValue,
		brokerDetails: inititalValue,
	});

	const collapseAll = useCallback(() => {
		setCollapsedItems(initCollapsedItems(true));
	}, []);

	const expandAll = useCallback(() => {
		setCollapsedItems(initCollapsedItems(false));
	}, []);

	const handleOnChange = useCallback(
		(e: any) => {
			const { name, value } = e.target;
			let val = value === '' ? null : value;
			setFormData({
				...formData,
				[name]: val,
			});
		},
		[formData, setFormData]
	);

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setIsLoading(true);
		if (!validateForm(formErrors, setFormErrors, formData, formErrors.additionalDrivers[0])) {
			expandAll();
			const body = document.querySelector('.cover-note__heading');
			setIsLoading(false);
			if (body === null) return;

			body.scrollIntoView();
			return;
		}
		await postFormData();
	};

	const handleExtendCoverNote = async (event: any) => {
		event.preventDefault();
		setIsLoading(true);
		if (!validateForm(formErrors, setFormErrors, formData, formErrors.additionalDrivers[0])) {
			expandAll();
			const body = document.querySelector('.cover-note__heading');
			setIsLoading(false);
			if (body === null) return;

			body.scrollIntoView();
			return;
		}
		await postExtendCoverNote();
	};

	const getNewExpiredDate = useCallback(() => {
		let expiredDate = new Date(state?.coverNote?.expired);
		expiredDate.setDate(expiredDate.getDate() + parseInt(numberOfDaysToExpire));
		return expiredDate.toISOString();
	}, [numberOfDaysToExpire, state?.coverNote?.expired]);

	const postExtendCoverNote = useCallback(async () => {
		const newToken = await silentRenew();
		const request = await postData(`coverNotes/${state?.coverNote?.id}/issueContinuation`, { newExpireDate: getNewExpiredDate() }, newToken).catch(() => {
			setIsLoading(false);
			return;
		});

		if (!request?.success && request?.statusCode >= 500) {
			setHasError(true);
			setIsLoading(false);
			return;
		}

		if (!request?.success) {
			handleApiResponseForm(request, initialFormErrors, setFormErrors);
			setIsLoading(false);
			return;
		}

		const id = request.data.id;
		setIsLoading(false);

		navigate(`/coverNotes/${id}/created`);
	}, [silentRenew, state?.coverNote?.id, getNewExpiredDate, navigate]);

	useEffect(() => {
		if (pathname.includes('extension')) {
			setCollapsedItems({
				...collapsedItems,
				cover: false,
				policyDetails: true,
			});
			const collapseElement = document.querySelector('.cover');
			if (collapseElement === null) return;

			collapseElement?.scrollIntoView();
		}
	}, [pathname]);

	const postFormData = useCallback(async () => {
		const newToken = await silentRenew();
		const request = await postData('coverNotes', formData, newToken).catch(() => {
			setHasError(true);
			setIsLoading(false);
			return;
		});

		if (!request?.success && request?.statusCode >= 500) {
			setHasError(true);
			setIsLoading(false);
			return;
		}

		if (!request?.success) {
			handleApiResponseForm(request, initialFormErrors, setFormErrors);
			setHasError(true);
			setIsLoading(false);
			return;
		}

		const id = request.data.id;
		setIsLoading(false);

		navigate(`/coverNotes/${id}/created`);
	}, [silentRenew, formData, navigate]);

	return (
		<MainLayout isPrivate loading={isLoading} forbidden={!user?.coverNote}>
			<div className='cover-note'>
				<Link className='typography cover-note__go-back typography--link typography--bold' to={'/'}>
					Go back
				</Link>
				<div className='cover-note__heading'>
					<div className='cover-note__heading__text'>
						<span className='typography typography--h2'>Issue Cover Note</span>
						<span className='typography'>Please complete the cover note information form and hit the "submit" button to issue the cover note.</span>
						<span className='typography'>Once completed, your cover note will be instantly emailed to the email address you provided at the time of registration.</span>
					</div>
					{!isCollapsed ? (
						<Button type={ButtonType.Secondary} size='medium' onClick={collapseAll}>
							Collapse All
						</Button>
					) : (
						<Button type={ButtonType.Secondary} size='medium' onClick={expandAll}>
							Expand All
						</Button>
					)}
				</div>
				<div className='form form--small-gap'>
					{user?.isAdmin && (
						<Collapsible
							title='Broker details'
							collapsed={collapsedItems.brokerDetails}
							handleCollapsed={() =>
								setCollapsedItems((items) => ({
									...items,
									brokerDetails: !items.brokerDetails,
								}))
							}
						>
							<div className='cover-note__collapsible-wrapper'>
								<BrokerDetails formData={formData} isExtension={!!state?.isExtension} setFormErrors={setFormErrors} formErrors={formErrors} setFormData={setFormData} handleOnChange={handleOnChange} />
							</div>
						</Collapsible>
					)}

					<Collapsible
						title='Policy details'
						collapsed={collapsedItems.policyDetails}
						handleCollapsed={() =>
							setCollapsedItems((items) => ({
								...items,
								policyDetails: !items.policyDetails,
							}))
						}
					>
						<div className='cover-note__collapsible-wrapper'>
							<PolicyDetails formData={formData} isExtension={!!state?.isExtension} formErrors={formErrors} setFormData={setFormData} handleOnChange={handleOnChange} />
						</div>
					</Collapsible>
					<Collapsible
						title='Policyholder details'
						collapsed={collapsedItems.policyHolderDetails}
						handleCollapsed={() =>
							setCollapsedItems((items) => ({
								...items,
								policyHolderDetails: !items.policyHolderDetails,
							}))
						}
					>
						<div className='cover-note__collapsible-wrapper'>
							<PolicyHolderDetails
								formData={formData}
								isExtension={!!state?.isExtension}
								setFormErrors={setFormErrors}
								formErrors={formErrors}
								setFormData={setFormData}
								isReused={!!state}
								handleOnChange={handleOnChange}
							/>
						</div>
					</Collapsible>
					<Collapsible
						title='Driver details'
						collapsed={collapsedItems.driverDetails}
						handleCollapsed={() =>
							setCollapsedItems((items) => ({
								...items,
								driverDetails: !items.driverDetails,
							}))
						}
					>
						<DriverDetails formData={formData} isExtension={!!state?.isExtension} formErrors={formErrors} isReused={!!state} setFormData={setFormData} setFormErrors={setFormErrors} />
					</Collapsible>
					<Collapsible
						title='Vehicle details'
						collapsed={collapsedItems.vehicleDetails}
						handleCollapsed={() =>
							setCollapsedItems((items) => ({
								...items,
								vehicleDetails: !items.vehicleDetails,
							}))
						}
					>
						<div className='cover-note__collapsible-wrapper'>
							<VehicleDetails
								setFormErrors={setFormErrors}
								isExtension={!!state?.isExtension}
								formData={formData}
								formErrors={formErrors}
								isReused={!!state}
								setFormData={setFormData}
								handleOnChange={handleOnChange}
							/>
						</div>
					</Collapsible>
					<Collapsible
						title='Policy use'
						collapsed={collapsedItems.policyUse}
						handleCollapsed={() =>
							setCollapsedItems((items) => ({
								...items,
								policyUse: !items.policyUse,
							}))
						}
					>
						<div className='cover-note__collapsible-wrapper'>
							<PolicyUse formData={formData} formErrors={formErrors} setFormData={setFormData} isExtension={!!state?.isExtension} />
						</div>
					</Collapsible>
					<Collapsible isScrollable={true} title='Cover' collapsed={collapsedItems.cover} handleCollapsed={() => setCollapsedItems((items) => ({ ...items, cover: !items.cover }))}>
						<div className='cover-note__collapsible-wrapper'>
							<Cover
								isExtension={state?.isExtension}
								numberOfDaysToExpire={numberOfDaysToExpire}
								setNumberOfDaysToExpire={setNumberOfDaysToExpire}
								formData={formData}
								formErrors={formErrors}
								setFormData={setFormData}
								handleOnChange={handleOnChange}
							/>
						</div>
					</Collapsible>
					<Collapsible
						title='Special Conditions, Restrictions &/Or Excesses'
						additionalLabel='(in addition to any standard policy excess)'
						collapsed={collapsedItems.specialConditions}
						handleCollapsed={() =>
							setCollapsedItems((items) => ({
								...items,
								specialConditions: !items.specialConditions,
							}))
						}
					>
						<div className='cover-note__collapsible-wrapper'>
							<SpecialConditions isExtension={state?.isExtension} formData={formData} formErrors={formErrors} handleOnChange={handleOnChange} />
						</div>
					</Collapsible>
					<div className='cover-note__notes'>
						<span className='typography text-2base typography--bold'>Return of Obsolete Cover Notes</span>
						<span className='typography'>As an appointed agent it is your responsibility to ensure any obsolete cover notes are returned by your client and destroyed on our behalf.</span>
					</div>
					<div className='cover-note__issue-button-wrapper'>
						{hasError && (
							<div className='cover-note__issue-button-wrapper__error'>
								<span className='typography typography--error'>Something went wrong.</span>
							</div>
						)}
						<Button type={ButtonType.Primary} size='medium' onClick={state?.isExtension ? handleExtendCoverNote : handleSubmit}>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</MainLayout>
	);
};

export default IssueCoverNote;
